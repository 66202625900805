<template>
  <Layout>
    <PageHeader :items="getItems()" title="Allegro Statystyki" />

    <div class="row">
      <allegro-statistic title="Aukcji w kolejce" :value="statistics.auctionsInQueue.toString()" icon="fa fa-stopwatch" />
      <allegro-statistic title="Aktywnych aukcji" :value="statistics.activeAuctionCount.toString()" icon="fa fa-check" />
      <allegro-statistic title="Wszystkich aukcji" :value="statistics.totalAuctionCount.toString()" icon="fa fa-list" />
    </div>

  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import AllegroStatistic from "@/components/allegro/allegro-statistic.vue";
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";

export default {

  components: {
    AllegroStatistic,
    Layout,
    PageHeader
  },

  data() {
    return {
      intervalId: 0,

      statistics: {
        auctionsInQueue: 0,
        activeAuctionCount: 0,
        totalAuctionCount: 0
      }
    }
  },

  methods: {
    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: "Allegro Statystyki",
          active: true
        }
      ]
    },

    async updateStatistics() {
      try {
        const {data} = await axios.get(`/allegro/statistics/auction`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.statistics = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    }
  },

  async created() {
    const that = this
    this.intervalId = setInterval(async () => {
      await that.updateStatistics()
    }, 1000);
  },

  beforeDestroy() {
    if (!this.intervalId) {
      return
    }

    clearInterval(this.intervalId);
  }

}
</script>